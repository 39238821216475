@import '../../../../scss/theme-bootstrap';

@mixin welcome-overlay-fonts {
  font-family: $body-font;
  font-size: 15px;
  color: $color-warm-grey;
}

@mixin loyalty-loader {
  background-image: url('/media/export/cms/loading.gif');
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-#{$ldirection}: 5px;
}

@mixin loyalty-button--disabled {
  background-color: $color-light-gray;
}

@mixin common-font {
  font-size: 11px;
  line-height: 1.3;
}

@mixin common-margin {
  margin-bottom: 12px;
}

.welcome15-overlay {
  &#cboxOverlay {
    background: $color-black;
  }
  @include breakpoint($landscape-up) {
    -ms-overflow-style: none;
  }
  #cboxContent {
    padding: 25px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  #cboxLoadedContent {
    overflow: hidden !important;
  }
  #cboxClose {
    top: 30px;
    #{$rdirection}: 25px;
    color: $color-black;
    font-size: 18px;
  }
  .welcome-overlay {
    &__leftcontent,
    &__rightcontent {
      @include breakpoint($landscape-up) {
        width: 50%;
        float: $ldirection;
      }
    }
    &__leftcontent {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
      img {
        height: 550px;
        width: 450px;
      }
    }
    &__rightcontent {
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 25px 30px 25px 25px);
      }
    }
    &__header {
      font-family: $bb-sans;
      font-size: 40px;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 15px;
    }
    &__copy {
      font-family: $body-font;
      font-size: 17px;
      margin-bottom: 20px;
      &__text {
        font-weight: bold;
      }
    }
    &__email {
      .form-text {
        margin-bottom: 20px;
        width: 100%;
        height: 54px;
        border: 1px solid $color-gray-border;
        &::placeholder {
          @include welcome-overlay-fonts;
        }
        &::-webkit-input-placeholder {
          @include welcome-overlay-fonts;
        }
        &__name {
          margin-bottom: 10px;
        }
      }
    }
    &__checkbox {
      margin-bottom: 10px;
      @include breakpoint($landscape-up) {
        margin-bottom: 40px;
      }
      input[type='checkbox'] {
        &:checked ~ label:before {
          vertical-align: sub;
          font-size: 17px;
        }
      }
      .label {
        &:before {
          vertical-align: sub;
          font-size: 17px;
        }
        &__content {
          @include welcome-overlay-fonts;
          display: inline-block;
          width: 75%;
          vertical-align: top;
          .terms-link {
            color: $color-warm-grey;
          }
        }
      }
      &__terms:checked + .label {
        color: $color-black;
        &:before {
          background-color: $color-black;
        }
      }
    }
    &__submit {
      margin-top: 60px;
      &__button {
        @include breakpoint($landscape-up) {
          margin: 0 0 20px;
        }
        margin: 35px 0 25px;
        padding: 10px;
        width: 100%;
        height: inherit;
        &.disabled {
          background: $color-black;
        }
      }
    }
    &__finish {
      @include breakpoint($landscape-up) {
        margin-top: 30px;
      }
    }
    &__footer__link {
      @include welcome-overlay-fonts;
      text-transform: uppercase;
      text-align: center;
      margin-top: 5px;
      display: block;
    }
    &__footer__message {
      @include welcome-overlay-fonts;
      &__termstext {
        color: $color-warm-grey;
      }
      &__conditions {
        @include breakpoint($landscape-up) {
          padding-top: 21px;
        }
      }
    }
    &--signup {
      .welcome-overlay {
        &__submit__button {
          margin: 2px 0 13px;
        }
      }
    }
    &--offer {
      text-align: center;
      .welcome-overlay {
        &__header {
          .welcome {
            &__display {
              display: block;
            }
            &__text {
              font-size: 18px;
            }
            &__bobbitext {
              margin: 25px 0 10px;
              letter-spacing: 6px;
              font-size: 40px;
            }
            &__clubtext {
              font-size: 30px;
              letter-spacing: 6px;
            }
          }
        }
        &__copy {
          margin: 60px 0 25px;
        }
        &__offer-code {
          text-transform: uppercase;
          font-size: 20px;
          margin-bottom: 50px;
        }
      }
    }
    &__label {
      padding-top: 5px;
      position: relative;
      cursor: pointer;
      text-decoration: underline;
      &__overlay {
        position: absolute;
        z-index: -9999;
        opacity: 0;
        background-color: $color-white;
        padding: 15px;
        bottom: 15px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid $color-black;
        @include breakpoint($landscape-up) {
          max-width: 395px;
        }
        &.visible {
          display: block;
          z-index: 1;
          opacity: 1;
        }
      }
    }
  }
  &--txt-small {
    .welcome-overlay {
      &__indent1 {
        @include breakpoint($landscape-up) {
          margin-left: 25px;
        }
      }
      &__indent2 {
        @include breakpoint($landscape-up) {
          margin-left: 50px;
        }
      }
      &__header {
        font-size: 30px;
      }
      &__checkbox {
        @include common-margin;
        .label {
          &__content {
            @include common-font;
            vertical-align: middle;
          }
        }
      }
      &__checkbox--second {
        margin-bottom: 5px;
      }
      &__checkbox--third {
        margin-bottom: 12px;
      }
      &__copy {
        @include common-margin;
        font-size: 13px;
        line-height: 1.3;
        &--second {
          @include common-font;
        }
      }
      &__email {
        .form-text {
          @include common-margin;
        }
      }
      &__phone_number {
        .form-text {
          height: 18px;
          line-height: 15px;
          padding: 2px 5px;
          margin: 0;
          font-size: 12px;
          border: 1px solid $color-gray-border;
          &::placeholder {
            @include welcome-overlay-fonts;
          }
          &::-webkit-input-placeholder {
            @include welcome-overlay-fonts;
          }
        }
      }
      &__submit {
        margin-top: 0;
      }
    }
  }
  .loyalty {
    &__form__error__message {
      @include common-font;
    }
  }
}

.loyalty {
  &-loader {
    @include loyalty-loader;
  }
  &-button--disabled {
    @include loyalty-button--disabled;
  }
  &__form__error__message {
    color: $color-red;
  }
}
